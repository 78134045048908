
import { Component, Vue, Prop } from "vue-property-decorator";
import { cartStore } from "@/store/modules/cart";
import { menuStore } from "@/store/modules/menu";

import ICart from "@/store/models/cart";
import IFood, { LabelsEntity, ItemsEntity } from "@/store/models/food";
import dayjs from "dayjs";

import md5 from "md5";
import { Picture } from "@/components";
import { merchantStore } from "@/store/modules/merchant";
import { miniProgramPlatforms } from "@/constant/platform";
import empty from "is-empty";
import AlcoholModal from "@/components/Custom/AlcoholModal.vue";
import { uiStore } from "@/store/modules/ui";
import { addItemToEnvelope } from "@sentry/utils";

interface Attribute {
  id: string;
  name: string;
  description: string;
  labels: LabelsEntity[];
}
interface Side {
  id: string;
  name: string;
  description: string;
  items: ItemsEntity[];
}
interface Food {
  id: string;
  attributes: Array<Attribute>;
  sides: Array<Side>;
  price: {
    currencyType: string;
    amount: number;
  };
}
@Component({
  name: "FoodItem",
  components: {
    Picture,
    AlcoholModal
  }
})
export default class FoodItem extends Vue {
  @Prop({
    required: true
  })
  readonly food!: IFood;

  @Prop({
    default: false
  })
  readonly isHorizontal?: boolean;
  campaignID: string | null = "";
  campaignPrice = -1;
  remark = "";
  combinationPrice = 0;
  cartId = "";
  selectedFood = {
    id: "",
    attributes: [] as Attribute[],
    sides: [] as Side[],
    price: {
      currencyType: "",
      amount: 0
    }
  };
  isShowAlcoholModal = false;

  get isCartContainsAlcohol() {
    return cartStore.containsAlcohol;
  }

  isCartContainMealPlan() {
    return cartStore.cart.some((v) => v.selectedDineType == "MEAL_PLAN");
  }

  get isMoreDetail() {
    const items = this.$store.state.cartStore.cart.filter(
      (cart: ICart) => cart.item.id === this.food.id && cart.remark !== ""
    );
    return (
      (
        this.food.sides?.filter(
          (side) => ((side && side.items) || []).length > 0
        ) || []
      ).length > 0 ||
      (this.food.attributes || []).length > 0 ||
      items.length > 0
    );
  }

  get screenWidth() {
    return window.screen.width;
  }
  get comboTaxAmount() {
    return (this.food.price.amount * this.food.tax?.rate) / 10000;
  }

  getCartId(value) {
    if (this.cartId === "") {
      const hashedItem = md5(JSON.stringify(value.food));
      const hashedSides = md5(JSON.stringify(value.selectedSides));
      const hashedAttrs = md5(JSON.stringify(value.selectedAttributes));
      const hashedRemark = md5(JSON.stringify(value.remark));
      return hashedItem + hashedAttrs + hashedSides + hashedRemark;
    } else return this.cartId;
  }

  isFoodActive() {
    const food = this.food;
    return food.isAvailable;
  }

  unavailableText() {
    const food = this.food;
    if (!this.isFoodActive() && food.nextAvailableSlot != "") {
      // Calculate the next available schedule, nearest to current hour
      const timeFormat = this.$i18n.locale == "cn" ? "A h:mm" : "h:mm A";
      const dayFormat =
        this.$i18n.locale == "cn" ? "M月D日, A h:mm" : "MMM D, h:mm A";
      const now = dayjs();
      const nextSlot = dayjs(food.nextAvailableSlot);

      if (nextSlot.isAfter(now)) {
        const format = now.isSame(nextSlot, "day") ? timeFormat : dayFormat;
        return this.$t("FoodItem.available-time", {
          time: nextSlot.format(format)
        });
      }

      return this.$t("FoodItem.unavailable");
    }
    return this.$t("FoodItem.out-of-stock");
  }

  get foodName() {
    const isUsePlatformDetail = miniProgramPlatforms.includes(
      merchantStore.platform
    );
    const name = isUsePlatformDetail
      ? !empty(this.food?.platformDetail?.language?.[this.$i18n.locale]?.name)
        ? this.food?.platformDetail?.language?.[this.$i18n.locale]?.name
        : this.food?.name
      : !empty(this.food?.language?.[this.$i18n.locale]?.name)
      ? this.food?.language?.[this.$i18n.locale]?.name
      : this.food?.name;

    return name;
  }

  get foodImage() {
    const isUsePlatformDetail = miniProgramPlatforms.includes(
      merchantStore.platform
    );
    let image =
      isUsePlatformDetail && !empty(this.food?.platformDetail?.imageUrl)
        ? this.food?.platformDetail?.imageUrl
        : this.food?.imageUrl || [];
    return image;
  }

  get formattedDescription() {
    const isUsePlatformDetail = miniProgramPlatforms.includes(
      merchantStore.platform
    );
    const description = isUsePlatformDetail
      ? !empty(
          this.food?.platformDetail?.language?.[this.$i18n.locale]?.description
        )
        ? this.food?.platformDetail?.language?.[this.$i18n.locale]?.description
        : this.food?.description
      : !empty(this.food?.language?.[this.$i18n.locale]?.description)
      ? this.food?.language?.[this.$i18n.locale]?.description
      : this.food?.description;
    return (description || "").replace(/(?:\r\n|\r|\n)/g, "<br />");
  }

  get isCampaignPrice() {
    this.campaignID = menuStore.getCampaignID(
      this.food.campaignIDs,
      this.food.price.amount
    );
    this.campaignPrice = menuStore.getCampaignPrice(
      this.campaignID,
      this.food.price.amount
    );

    return this.campaignPrice > -1;
  }

  addFood() {
    if (this.availableFoodQuantity === 0) {
      alert("Sold out");
    } else {
      if (this.food.foodConformity == "ALCOHOL" && !cartStore.containsAlcohol) {
        this.isShowAlcoholModal = true;
        return;
      }
      this.updateCart(1);
    }
  }

  removeFood() {
    this.updateCart(-1);
  }

  updateCart(quantity) {
    const { sides, attributes } = this.selectedFood;

    const item = {
      cartId: "",
      food: this.food,
      selectedSides: sides,
      selectedAttributes: attributes,
      combinationPricePerItem: this.food.price.amount * 1.0,
      comboTaxAmount: this.comboTaxAmount,
      campaignDiscountAmount:
        this.campaignPrice > -1
          ? this.food.price.amount - this.campaignPrice
          : 0,
      itemQuantity: quantity,
      remark: this.remark,
      campaignID: this.campaignID
    };
    cartStore.addToCart(item);
  }

  onFood() {
    this.$router.push({
      name: "FoodView",
      params: { id: this.$route.params.id, foodId: this.food.id }
    });
  }

  get quantity() {
    return cartStore.cart
      .filter((each) => each.item.id === this.food?.id)
      .reduce((a, b) => a + b.itemQuantity, 0);
  }

  get availableFoodQuantity() {
    const quantity = this.food?.quantity;
    if (quantity == null) return -1;
    else {
      return quantity - this.quantity;
    }
  }
}
