var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_vm.availableFoodQuantity == 0 ? 'span' : 'router-link',{tag:"router-link",class:_vm.isHorizontal
        ? 'food-item flex flex-row justify-between items-stretch content-between transition rounded-lg shadow-notification'
        : 'food-item food-hover p-2 flex flex-row md:flex-col justify-between items-stretch content-between lg:mb-4',attrs:{"to":{
      name: 'FoodView',
      params: { id: _vm.$route.params.id, foodId: _vm.food.id }
    },"disabled":!_vm.isFoodActive()}},[_c('div',{class:_vm.isHorizontal
          ? 'rounded md:rounded-md relative self-center w-24 h-24 flex-shrink-0 overflow-hidden select-none pointer-events-none'
          : 'rounded md:rounded-md relative self-center w-24 h-24 md:w-48 md:h-48 flex-shrink-0 overflow-hidden select-none pointer-events-none'},[_c('Picture',{attrs:{"urls":_vm.foodImage,"name":_vm.foodName,"imgClass":_vm.isHorizontal
            ? `rounded md:rounded-md w-24 h-24 ${
                !_vm.isFoodActive() || _vm.availableFoodQuantity == 0
                  ? 'opacity-25'
                  : ''
              }`
            : `rounded md:rounded-md w-24 h-24 md:w-48 md:h-48 ${
                !_vm.isFoodActive() || _vm.availableFoodQuantity == 0
                  ? 'opacity-25'
                  : ''
              }`}}),(!_vm.isFoodActive() || _vm.availableFoodQuantity == 0)?_c('div',{class:_vm.isHorizontal
            ? 'duration-300 absolute inset-0 z-10 flex justify-center items-center mx-1'
            : 'duration-300 absolute inset-0 z-10 flex justify-center items-center text-md mx-1 md:text-xl'},[_c('span',{class:_vm.isHorizontal
              ? 'rounded p-1 text-xs text-black text-center bg-gray-200 bg-opacity-50'
              : 'rounded p-1 text-xs md:text-lg text-black text-center bg-gray-200 bg-opacity-50'},[_vm._v(_vm._s(_vm.unavailableText()))])]):_vm._e()],1),_c('div',{class:_vm.isHorizontal
          ? 'food flex flex-col justify-between items-stretch self-stretch w-full pl-2 pr-4 py-2'
          : 'food flex flex-col md:flex-col-reverse justify-between items-stretch self-stretch w-full ml-2 mr-1 md:ml-0 md:mt-2'},[_c('div',{class:_vm.isHorizontal ? 'w-48' : 'md:mt-2 md:w-48'},[_c('h3',{staticClass:"font-bold leading-tight line-clamp-name"},[_vm._v(" "+_vm._s(_vm.foodName)+" ")]),_c('p',{staticClass:"text-gray-600 text-sm leading-tight my-2 line-clamp",domProps:{"innerHTML":_vm._s(_vm.formattedDescription)}})]),_c('div',{staticClass:"flex flex-row justify-between items-end self-stretch"},[_c('b',{staticClass:"price text-base"},[_c('small',[_vm._v(_vm._s(_vm.food.price.currencyType === "MYR" ? "RM" : _vm.food.price.currencyType))]),(_vm.campaignPrice > -1)?_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(((_vm.campaignPrice + _vm.comboTaxAmount) / 100) .toFixed(2) .replace(".00", "")))]):_vm._e(),_c('span',{class:[
              'ml-1',
              _vm.isCampaignPrice
                ? 'line-through text-xs font-normal text-gray-400'
                : ''
            ]},[_vm._v(_vm._s(((_vm.food.price.amount + _vm.comboTaxAmount) / 100) .toFixed(2) .replace(".00", "")))])]),_c('div',{class:`flex-shrink-none -mr-1 md:mr-0 ${
              !_vm.isFoodActive() ? 'opacity-25' : ''
            }`},[(_vm.isMoreDetail || _vm.isCartContainMealPlan())?_c('button',{staticClass:"incrementer plus sides items-center"},[_c('span',{staticClass:"block md:hidden"},[_c('SvgIcon',{staticClass:"feather feather-plus theme",attrs:{"name":"plus","width":"16","height":"16"}})],1),(_vm.quantity > 0)?_c('span',{staticClass:"text-xs theme",on:{"click":function($event){$event.preventDefault();return _vm.onFood.apply(null, arguments)}}},[_c('span',{staticClass:"hidden md:inline"},[_vm._v(_vm._s(_vm.$t("FoodItem.add")))]),_vm._v(" "+_vm._s(_vm.$t("FoodItem.more"))+" ")]):_c('span',{staticClass:"text-xs theme",on:{"click":function($event){$event.preventDefault();return _vm.onFood.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("FoodItem.details"))+" ")]),(_vm.quantity > 0)?_c('b',{staticClass:"num"},[_vm._v(_vm._s(_vm.quantity))]):_vm._e()]):_c('div',{staticClass:"flex flex-row justify-between items-center"},[(_vm.quantity > 0)?_c('button',{directives:[{name:"waves",rawName:"v-waves"}],staticClass:"incrementer minus mr-2",on:{"click":function($event){$event.preventDefault();return _vm.removeFood()}}},[_c('SvgIcon',{staticClass:"feather feather-minus",attrs:{"name":"minus","width":"16","height":"16"}})],1):_vm._e(),(_vm.quantity > 0)?_c('p',{staticClass:"number"},[_vm._v(_vm._s(_vm.quantity))]):_vm._e(),_c('button',{staticClass:"incrementer plus",on:{"click":function($event){$event.preventDefault();return _vm.addFood.apply(null, arguments)}}},[_c('SvgIcon',{staticClass:"feather feather-plus theme",attrs:{"name":"plus","width":"16","height":"16"}})],1)])])])])]),(_vm.isShowAlcoholModal)?_c('alcohol-modal',{attrs:{"isModalOpen":_vm.isShowAlcoholModal},on:{"cancel":function($event){_vm.isShowAlcoholModal = false},"confirm":() => {
        _vm.isShowAlcoholModal = false;
        _vm.updateCart(1);
      }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }